import { useSuspenseQuery } from '@apollo/client';
import { useTranslations } from 'next-intl';
import { Link } from 'react-transition-progress/next';
import type { GetPickupEventsQuery } from '@/__generated__/graphql';
import { GetPickupEventsDocument, PickupEventSortOrder } from '@/__generated__/graphql';
import { Skeleton } from '@/blocks/home/skeleton';
import { Unit } from '@/components/layout/unit/Unit';
import { EventCard } from '@/components/ui/events/EventCard';
import { PATHS } from '@/constants/path';
import { dynamicPath } from '@/utils/dynamic-path';

export function PickupEvents() {
  const { data } = useSuspenseQuery(GetPickupEventsDocument, {
    variables: {
      first: 30,
      sortOrder: PickupEventSortOrder.PriorityAsc,
    },
  });
  const t = useTranslations();

  if (!data?.pickupEvents) return <Skeleton />;

  const { edges } = data.pickupEvents;

  // 空配列であればトルツメする
  if (edges.length === 0) return null;

  return (
    <Unit id='events'>
      <h1 className='pb-5 text-lg font-bold'>{t('common_pickup-events')}</h1>
      <div className='grid gap-y-2'>
        {edges.map((pickupEvent) => {
          const { node } = pickupEvent;
          const isEventPeriodDifferent =
            node.event.performanceInfo.eventPeriod.startDate !==
            node.event.performanceInfo.eventPeriod.endDate;
          const endDate = isEventPeriodDifferent
            ? node.event.performanceInfo.eventPeriod.endDate
            : undefined;

          return (
            <div key={node.id} className='flex h-32 flex-col overflow-hidden'>
              <Link
                href={dynamicPath(PATHS.EVENTS, { event_id: node.event.slug || node.event.id })}
              >
                <EventCard
                  subHeading={node.event.name}
                  heading={<EventArtistName artistInfo={node.event.performanceInfo.artists} />}
                  startDate={node.event.performanceInfo.eventPeriod.startDate}
                  endDate={endDate}
                  imageUrl={node.event.image?.url}
                  isShownButton={false}
                />
              </Link>
            </div>
          );
        })}
      </div>
    </Unit>
  );
}

type Props = {
  artistInfo: GetPickupEventsQuery['pickupEvents']['edges'][number]['node']['event']['performanceInfo']['artists'];
};
const EventArtistName = ({ artistInfo }: Props) => {
  if (artistInfo.length === 1) {
    return (
      <span className='line-clamp-3 break-all text-[1.6rem] font-bold leading-[1.9rem]'>
        {artistInfo[0].name}
      </span>
    );
  }
  // TODO: アーティスト名が長い場合はアーティスト名を省略しつつ、アーティスト名...＋「ほか」を3行以内で表示する仕様だが。実装が難しいため仕様を相談する
  if (artistInfo.length > 1) {
    return (
      <div className='line-clamp-3 break-all'>
        <span className='text-[1.6rem] font-bold leading-[1.9rem]'>{artistInfo[0].name}</span>
        <span className='ml-1 flex-shrink-0 text-[1.6rem] font-bold leading-[1.9rem]'>ほか</span>
      </div>
    );
  }
  return null;
};
