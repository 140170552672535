'use client';

import type { VirtualItem } from '@tanstack/react-virtual';
import { createContext, useContext, useEffect, useState } from 'react';
import type { PathValues } from '@/constants/path';

export const CUSTOM_CACHE_KEY = {
  ME_NORMAL: '/notifications/me_normal',
  ME_IMPORTANT: '/notifications/me_important',
  NEWS_NORMAL: '/notifications/news_normal',
  NEWS_IMPORTANT: '/notifications/news_important',
  SUPER_MESSAGE_BADGES: '/artists/[artist_slug]/super_message_badges',
  EVENTS_BADGES: '/artists/[artist_slug]/events_badges',
} as const;

export type CacheKey = PathValues | (typeof CUSTOM_CACHE_KEY)[keyof typeof CUSTOM_CACHE_KEY];

type Cache = {
  [_Key in CacheKey]?: {
    virtualizerScrollOffset: number;
    virtualizerMeasurementsCache: VirtualItem[];
  };
};

interface Context {
  cache: Cache;
  setVirtualizerScrollRestorationData(
    cacheKey: string,
    virtualizerScrollOffset: number,
    virtualizerMeasurementsCache: VirtualItem[],
  ): void;
}

const VirtualizerScrollRestorationContext = createContext<Context>({} as Context);

export function useVirtualizerScrollRestoration() {
  return useContext(VirtualizerScrollRestorationContext);
}

// キャッシュからスクロール位置を取得する
export const getInitialScrollOffset = (cache: Cache, cacheKey: CacheKey) => {
  const identifiedCache = cache[cacheKey];
  return identifiedCache ? identifiedCache.virtualizerScrollOffset : 0;
};

// キャッシュから測定値を取得する
export const getInitialMeasurementsCache = (cache: Cache, cacheKey: CacheKey) => {
  const identifiedCache = cache[cacheKey];
  return identifiedCache && identifiedCache.virtualizerMeasurementsCache.length !== 0
    ? identifiedCache.virtualizerMeasurementsCache
    : undefined;
};

export const VirtualizerScrollRestorationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [virtualizerCache, setVirtualizerCache] = useState<Cache>({});

  function setVirtualizerScrollRestorationData(
    cacheKey: CacheKey,
    virtualizerScrollOffset: number,
    virtualizerMeasurementsCache: VirtualItem[],
  ) {
    setVirtualizerCache((prev) => {
      return {
        ...prev,
        [`${cacheKey}`]: {
          virtualizerScrollOffset,
          virtualizerMeasurementsCache,
        },
      };
    });
  }

  // アプリケーション内で復元を制御するため、ブラウザによるスクロール復元を無効化
  useEffect(() => {
    history.scrollRestoration = 'manual';
    return () => {
      history.scrollRestoration = 'auto';
    };
  }, []);

  return (
    <VirtualizerScrollRestorationContext.Provider
      value={{
        cache: virtualizerCache,
        setVirtualizerScrollRestorationData,
      }}
    >
      {children}
    </VirtualizerScrollRestorationContext.Provider>
  );
};
