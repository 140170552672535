import { observeWindowOffset, useWindowVirtualizer } from '@tanstack/react-virtual';
import { useRef, type ReactNode, useEffect } from 'react';
import type { CacheKey } from '@/providers/virtualizer-scroll-restoration-provider';
import {
  useVirtualizerScrollRestoration,
  getInitialScrollOffset,
  getInitialMeasurementsCache,
} from '@/providers/virtualizer-scroll-restoration-provider';

type Props = {
  /** 仮想リストに表示したい要素の配列 */
  children: ReactNode[];
  /** 1行に表示する列数 */
  columnCount: number;
  /** リスト1行の高さの推定値(`px`)(初期値は`100`) */
  estimatedRowHeight?: number;
  /** 行間の余白(`px`)(初期値は`0`) */
  rowGap?: number;
  /** 列間の余白(`px`)(初期値は`0`) */
  columnGap?: number;
  /** 前後どれくらい先読みするか(初期値は`10`) */
  overscan?: number;
  /** 末尾に到達したときに呼ばれるコールバック */
  onEndReached?: () => Promise<void>;
  /**
   * Cacheを作成するためのユニークキー
   *
   * src/constants/path.tsを参照し画面名を設定するようにしてください。
   *
   * 複数のコンポーネントで当コンポーネントを利用する場合は、カスタムキャッシュキーを定義する必要があります。
   * カスタムキャッシュキーを設定するには、src/providers/virtualizer-scroll-restoration-provider.tsxの
   * CUSTOM_CACHE_KEYに値を定義して利用してください。
   *
   */
  cacheKey: CacheKey;
};

/**
 * 仮想グリッドリストの共通コンポーネント
 *
 * ウィンドウのスクロールに合わせて仮想スクロールしたい場合に使用
 * アイテムの高さが可変にも対応済み
 */
export const VirtualWindowGridList = ({
  children: items,
  columnCount,
  estimatedRowHeight = 100,
  rowGap = 0,
  columnGap = 0,
  overscan = 10,
  cacheKey,
  onEndReached,
}: Props) => {
  const virtualScrollAreaRef = useRef<HTMLDivElement>(null);
  const isFetching = useRef(false);
  const rowCount = Math.ceil(items.length / columnCount);
  const windowScrollY = typeof window !== 'undefined' ? window.scrollY : 0;
  const virtualScrollAreaOffsetTop = virtualScrollAreaRef.current?.getBoundingClientRect().top ?? 0;
  const offsetTopFromWindowTop = virtualScrollAreaOffsetTop + windowScrollY;

  const { cache, setVirtualizerScrollRestorationData } = useVirtualizerScrollRestoration();

  const {
    getVirtualItems,
    getTotalSize,
    measureElement,
    options: { scrollMargin },
  } = useWindowVirtualizer({
    count: rowCount,
    observeElementOffset: (instance, cb) => {
      return observeWindowOffset(instance, (offset, isScrolling) => {
        if (isScrolling) {
          cb(offset, isScrolling);
        }
        /**
         * MEMO
         * スクロールが止まった時に、スクロール位置と測定された要素達を保存
         * SPA遷移中、遷移後のスクロール位置を捕捉してしまうことがあるので、virtualScrollAreaRefの存在チェックも行う
         */
        if (!isScrolling && virtualScrollAreaRef.current) {
          setVirtualizerScrollRestorationData(cacheKey, offset, instance.measurementsCache);
        }
      });
    },
    initialOffset: getInitialScrollOffset(cache, cacheKey),
    initialMeasurementsCache: getInitialMeasurementsCache(cache, cacheKey),
    scrollMargin: offsetTopFromWindowTop,
    estimateSize: () => estimatedRowHeight,
    overscan,
    gap: rowGap,
  });

  const virtualItems = getVirtualItems();
  const totalSize = getTotalSize();

  useEffect(() => {
    if (!onEndReached) return;
    if (isFetching.current) return;
    const [lastItem] = [...virtualItems].reverse();
    if (!lastItem) return;
    (async () => {
      const lastRowIndex = rowCount - 1;
      const isEndReached = lastItem.index >= lastRowIndex;
      if (isEndReached) {
        isFetching.current = true;
        await onEndReached();
        isFetching.current = false;
      }
    })();
  }, [onEndReached, virtualItems]);

  return (
    <div ref={virtualScrollAreaRef}>
      <div className='relative' style={{ height: `${totalSize}px` }}>
        {virtualItems.map((row) => (
          <div
            key={row.index}
            ref={measureElement}
            data-index={row.index}
            className='absolute left-0 top-0 flex w-full'
            style={{
              gap: `${columnGap}px`,
              transform: `translateY(${row.start - scrollMargin}px)`,
            }}
          >
            {[...Array(columnCount)].map((_, columnIndex) => {
              const itemIndex = row.index * columnCount + columnIndex;
              const item = items[itemIndex];
              if (!item) return null;
              return (
                <div
                  key={itemIndex}
                  style={{
                    width: `calc((100% - ${columnGap}px * ${columnCount - 1}) / ${columnCount})`,
                    minHeight: row.index === 0 ? estimatedRowHeight : row.size,
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
