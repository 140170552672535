import clsx from 'clsx';
import dayjs from 'dayjs';
import Image from 'next/image';
import type { ReactNode } from 'react';
import React from 'react';
import { Button } from '@/components/ui/button/Button';
import { DEFAULT_EVENT_IMAGE_PATH } from '@/constants/image-path';
import { formatDateToYMD } from '@/utils/format-date';

type Props = {
  heading: ReactNode; // メインテキスト
  subHeading?: string; // サブテキスト
  className?: string; // 任意でクラス名
  startDate: string; // 開始日時 ex) 2022-03-06T08:23:45.000Z
  endDate?: string; // 終了日時 ex) 2022-03-06T08:23:45.000Z
  imageUrl?: string; // 画像URL
  buttonColor?: string; // ボタンの色
  onClick?: () => void; // チケット購入ボタン押下時
  isShownButton?: boolean; // チケット購入ボタンを表示するかのフラグ
};

export const EventCard: React.FC<Props> = ({
  heading,
  subHeading,
  className,
  startDate,
  endDate,
  imageUrl,
  buttonColor = '#000',
  onClick,
  isShownButton = true,
}) => {
  const isSameDate = dayjs(startDate).isSame(dayjs(endDate));
  const formattedStartDate = formatDateToYMD(startDate);
  const formattedEndDate = endDate ? formatDateToYMD(endDate) : null;

  return (
    <div className={clsx('relative aspect-[671/257]', className)}>
      <Bg className='z-0' fill='#fff' />
      <div className='z-1 relative flex h-full' style={{ clipPath: 'url(#clipPath)' }}>
        <div className='aspect-[1] h-full flex-shrink-0 bg-[#D9D9D9]'>
          <Image
            src={imageUrl ?? DEFAULT_EVENT_IMAGE_PATH}
            alt=''
            className='h-full w-full object-cover'
            width={128}
            height={128}
          />
        </div>
        <div className='py-[10px] pl-[12px] pr-[16px]'>
          {subHeading && <span className='line-clamp-2 break-all text-label-14'>{subHeading}</span>}
          <span className='line-clamp-3 break-all text-head-16'>{heading}</span>
          <div className='text-sub-12 text-[#999]'>
            {formattedStartDate}
            {formattedEndDate && !isSameDate && (
              <span>
                {' ~ '}
                {formattedEndDate}
              </span>
            )}
          </div>
          {isShownButton && (
            <Button
              className='absolute bottom-[10px] right-[16px]'
              color={buttonColor}
              label='チケット購入'
              onClick={onClick}
              size='sm'
              variant='default'
            />
          )}
        </div>
      </div>
      <Bg className='z-2' stroke='#d6d6d6' />
    </div>
  );
};

const Bg: React.FC<{ className: string; fill?: string; stroke?: string }> = ({
  className,
  fill = 'none',
  stroke = 'none',
}) => {
  return (
    <svg
      className={clsx(className, 'pointer-events-none absolute left-0 top-0')}
      viewBox='0 0 671 257'
    >
      <path
        d='M670.37,104.37s.08,0,.13,0V.5h-412c0,1.1-.9,2-2,2s-2-.9-2-2H.5v256h254c0-1.1.9-2,2-2s2,.89,2,2h412v-103.88s-.08,0-.13,0c-13.32,0-24.13-10.8-24.13-24.13s10.8-24.13,24.13-24.13ZM256.5,250.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,242.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,234.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,226.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.89,2,2-.9,2-2,2ZM256.5,218.5c-1.1,0-2-.89-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,210.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,202.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,194.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.89,2,2-.9,2-2,2ZM256.5,186.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,178.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,170.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,162.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,154.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,146.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,138.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,130.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,122.5c-1.1,0-2-.89-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,114.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,106.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,98.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,90.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,82.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,74.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,66.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,58.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,50.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,42.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,34.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,26.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.89,2,2-.9,2-2,2ZM256.5,18.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM256.5,10.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Z'
        fill={fill}
        fillRule='evenodd'
        stroke={stroke}
        strokeMiterlimit='10'
      />
    </svg>
  );
};
