import clsx from 'clsx';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

export const ARROW_POSITION = {
  TOP_LEFT: 'topLeft',
  TOP_CENTER: 'topCenter',
  TOP_RIGHT: 'topRight',
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM_CENTER: 'bottomCenter',
  BOTTOM_RIGHT: 'bottomRight',
  LEFT_TOP: 'leftTop',
  LEFT_MIDDLE: 'leftMiddle',
  LEFT_BOTTOM: 'leftBottom',
  RIGHT_TOP: 'rightTop',
  RIGHT_MIDDLE: 'rightMiddle',
  RIGHT_BOTTOM: 'rightBottom',
};

export type ArrowPosition = (typeof ARROW_POSITION)[keyof typeof ARROW_POSITION];

type Props = {
  text: ReactNode;
  visible: boolean;
  arrowPosition?: ArrowPosition;
  className?: string;
};

export const Tooltip: React.FC<Props> = ({
  text,
  visible,
  arrowPosition = ARROW_POSITION.BOTTOM_CENTER,
  className,
}) => {
  const translate = useMemo(() => {
    switch (arrowPosition) {
      case ARROW_POSITION.BOTTOM_CENTER:
      case ARROW_POSITION.BOTTOM_LEFT:
      case ARROW_POSITION.BOTTOM_RIGHT:
        return '-translate-y-2';
      case ARROW_POSITION.TOP_CENTER:
      case ARROW_POSITION.TOP_LEFT:
      case ARROW_POSITION.TOP_RIGHT:
        return 'translate-y-2';
      case ARROW_POSITION.LEFT_TOP:
      case ARROW_POSITION.LEFT_MIDDLE:
      case ARROW_POSITION.LEFT_BOTTOM:
        return 'translate-x-2';
      case ARROW_POSITION.RIGHT_TOP:
      case ARROW_POSITION.RIGHT_MIDDLE:
      case ARROW_POSITION.RIGHT_BOTTOM:
        return '-translate-x-2';
      default:
        return 'translate-y-2';
    }
  }, [arrowPosition]);

  return (
    <div
      className={twMerge(
        clsx(
          'absolute flex w-[21.2rem] items-center justify-center rounded-[0.2rem] border border-black bg-white px-4 py-3 text-left text-xs',
          visible
            ? 'visible translate-y-0 opacity-100 duration-[400ms] ease-out'
            : `invisible opacity-0 duration-[200ms] ease-out ${translate}`,
          className,
        ),
      )}
    >
      {text}
      <Arrow arrowPosition={arrowPosition} />
    </div>
  );
};

const Arrow: React.FC<{ arrowPosition: ArrowPosition }> = ({ arrowPosition }) => {
  const className: string = useMemo(() => {
    switch (arrowPosition) {
      case ARROW_POSITION.BOTTOM_CENTER:
        return '-bottom-2.5';
      case ARROW_POSITION.BOTTOM_LEFT:
        return '-bottom-2.5 left-5';
      case ARROW_POSITION.BOTTOM_RIGHT:
        return '-bottom-2.5 right-5';
      case ARROW_POSITION.TOP_CENTER:
        return '-top-2.5 scale-y-[-1]';
      case ARROW_POSITION.TOP_LEFT:
        return '-top-2.5 left-5 scale-y-[-1]';
      case ARROW_POSITION.TOP_RIGHT:
        return '-top-2.5 right-5 scale-y-[-1]';
      case ARROW_POSITION.LEFT_TOP:
        return 'top-5 left-[-1.1rem] rotate-90';
      case ARROW_POSITION.LEFT_MIDDLE:
        return ' left-[-1.1rem] rotate-90';
      case ARROW_POSITION.LEFT_BOTTOM:
        return 'bottom-5 left-[-1.1rem] rotate-90';
      case ARROW_POSITION.RIGHT_TOP:
        return 'top-5 right-[-1.1rem] -rotate-90';
      case ARROW_POSITION.RIGHT_MIDDLE:
        return ' right-[-1.1rem] -rotate-90';
      case ARROW_POSITION.RIGHT_BOTTOM:
        return 'bottom-5 right-[-1.1rem] -rotate-90';
      default:
        return '-bottom-2.5';
    }
  }, [arrowPosition]);

  return (
    <svg
      width='12'
      height='10'
      viewBox='0 0 12 10'
      fill='none'
      className={clsx('absolute', className)}
    >
      <path d='M5.99984 0H0.666504L5.99984 8L11.3332 0H5.99984Z' fill='white' />
      <path d='M6 8L0.666666 0H0V1H0.390523L6 9.41421L11.6095 1H12V0H11.3333L6 8Z' fill='black' />
    </svg>
  );
};
