/**
 * ハッシュに基づいてページ内の特定のセクションにスムーズにスクロールします
 * @param {string} hash - スクロール対象のセクションのハッシュ (例: "#section1")
 */
export const scrollToSection = (hash: string) => {
  if (typeof window !== 'undefined') {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);
    const header = document.querySelector('header');

    if (element) {
      let offset = 0;
      if (window.innerWidth < 745) {
        offset = header ? header.offsetHeight : 65;
      }

      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      window.location.hash = `#${id}`;
    }
  }
};
