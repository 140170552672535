export const PATHS = {
  ROOT: '/',
  SIGN_UP_COMPLETE: '/?modal=welcome',
  EMAIL_REGISTERED: '/?modal=email-registered',
  CHANNELS: '/channels',
  FOLLOWING: '/following',
  NOTIFICATIONS_ME: '/notifications/me',
  NOTIFICATIONS_ME_DETAIL: '/notifications/me/[my_notification_id]',
  NOTIFICATIONS_NEWS: '/notifications/news',
  NOTIFICATIONS_NEWS_DETAIL: '/notifications/news/[news_id]',
  SETTINGS: '/settings',
  SETTINGS_PAYMENT_HISTORY: '/settings/payment-history',
  SETTINGS_PAYMENT_HISTORY_DETAIL: '/settings/payment-history/[history_id]',
  SETTINGS_PAYMENT_METHODS: '/settings/payment-methods',
  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_NOTIFICATIONS: '/settings/notifications',
  SETTINGS_LANGUAGE: '/settings/language',
  SETTINGS_EMAIL: '/settings/email',
  ABOUT: '/about',
  ABOUT_FOR_ARTISTS: 'https://klew-for-artist.studio.site',
  ARTISTS_BASE: '/artists',
  ARTISTS: '/artists/[artist_slug]',
  ARTISTS_BADGES: '/artists/[artist_slug]/badges',
  ARTISTS_PAST_EVENTS: '/artists/[artist_slug]/past-events',
  ARTISTS_CHANNELS: '/artists/[artist_slug]/channels/[channel_id]',
  ARTISTS_CHANNELS_POSTS: '/artists/[artist_slug]/channels/[channel_id]/posts/[post_id]',
  SUPER_MESSAGE_COMPLETE: '/artists/[artist_slug]/channels/[channel_id]/complete',
  SIGN_UP: '/sign-up',
  SIGN_UP_PROFILE: '/sign-up/profile',
  EVENTS_BASE: '/events',
  EVENTS: '/events/[event_id]',
  EVENTS_PERFORMANCES_BASE: '/events/performances',
  EVENTS_PERFORMANCES: '/events/performances/[performance_id]',
  EVENTS_PERFORMANCES_UPLOAD: '/events/performances/[performance_id]/ticket-upload',
  EVENTS_SALE_SCHEDULES_BASE: '/events/sale-schedules',
  EVENTS_SALE_SCHEDULES: '/events/sale-schedules/[saleschedule_id]',
  EVENTS_SALE_SCHEDULES_COMPLETE: '/events/sale-schedules/[saleschedule_id]/complete',
  TICKETS: '/tickets/items',
  TICKETS_DETAIL: '/tickets/items/[ticket_application_id]',
  TICKETS_USED: '/tickets/items/used',
  TICKETS_LOTTERY: '/tickets/lottery',
  TICKETS_LOTTERY_DETAILS: '/tickets/lottery/[ticket_application_id]',
  VERIFY_EMAIL: '/verify-email',
  CONTACT: 'https://help.klew.jp/hc/requests/new',
  FAQ: 'https://help.klew.jp/hc/categories/35734581091353',
  TERMS: 'https://help.klew.jp/hc/articles/35825750966681',
  PRIVACY_POLICY: 'https://kulture.jp/privacy.html',
  LEGAL: 'https://help.klew.jp/hc/articles/35861323648025',
  GUIDE_LINE: 'https://help.klew.jp/hc/articles/36709582155161',
  HELP: 'https://help.klew.jp/hc/articles/37523245220121',
  UPLOAD_REQUEST: 'https://help.klew.jp/hc/articles/36709943798681',
  X: 'https://x.com/klew_info',
  RECRUIT: 'https://note.com/kulture/n/na2d0af88493e',
} as const;

export const QUERIES = {
  WELCOME_MODAL: {
    key: 'modal',
    value: 'welcome',
  },
  FROM_ARTIST: {
    key: 'from',
    value: 'artist',
  },
  FROM_TICKET: {
    key: 'from',
    value: 'ticket',
  },
  FROM_RECEIVE_TICKET: {
    key: 'from',
    value: 'receive_ticket',
  },
  FROM_AUTH_ERROR: {
    key: 'from',
    value: 'auth_error',
  },
};

type PathsType = typeof PATHS;
type PathKeys = keyof typeof PATHS;
export type PathValues = (typeof PATHS)[PathKeys];
type AdditionalPaths = Pick<PathsType, 'NOTIFICATIONS_NEWS' | 'NOTIFICATIONS_NEWS_DETAIL'>;
export type AdditionalPathsType = AdditionalPaths[keyof AdditionalPaths][];
