'use client';
import React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const UnitContainer = ({ children, className }: Props) => {
  return (
    <div className={`relative overflow-hidden ${className}`}>
      <div className='z-1 relative flex flex-col gap-1'>{children}</div>
    </div>
  );
};
