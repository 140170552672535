import { useTranslations } from 'next-intl';
import { Unit } from '@/components/layout/unit/Unit';

export const ErrorUnit = () => {
  const t = useTranslations();

  return (
    <Unit>
      <h2 className='text-center font-bold'>{t('common-error-title')}</h2>
      <div className='mt-[2.8rem]'>
        <p className='text-center text-xs'>{t('common-error_description_1')}</p>
      </div>
    </Unit>
  );
};
