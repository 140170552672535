import { Unit } from '@/components/layout/unit/Unit';

export function Skeleton() {
  return (
    <Unit>
      <div className='space-y-7'>
        {[...Array(3)].map((_, index) => (
          <div className='animate-pulse' key={`skeleton-${index}`} role='status'>
            <div className='h-6 w-2/5 rounded bg-kl-smoke' role='status' />
            <div className='mt-3 space-y-1.5'>
              <div className='h-6 rounded bg-kl-smoke' role='status' />
              <div className='h-6 rounded bg-kl-smoke' role='status' />
              <div className='h-6 rounded bg-kl-smoke' role='status' />
              <div className='h-6 rounded bg-kl-smoke' role='status' />
            </div>
          </div>
        ))}
      </div>
    </Unit>
  );
}
