'use client';
import clsx from 'clsx';
import React, { useState } from 'react';
import { IconQuestionCircle } from '@/components/ui/icons';
import { Tooltip } from '@/components/ui/tooltip/Tooltip';

interface Props {
  id?: string;
  title?: string;
  titleColor?: string;
  contentPadding?: boolean;
  children: React.ReactNode;
  rightButton?: React.ReactNode;
  titleTooltipMessage?: string;
}

export const Unit = ({
  id,
  title,
  titleColor = '#000',
  children,
  titleTooltipMessage,
  rightButton,
  contentPadding = true,
}: Props) => {
  return (
    <section id={id} className='rounded-[3.2rem] bg-white pb-[4.2rem] pt-[3rem]'>
      {title && (
        <div className='flex items-start break-all px-5 pb-[2.4rem]'>
          <div className='flex w-full items-center justify-between gap-1.5'>
            <UnitTitle
              title={title}
              titleColor={titleColor}
              titleTooltipMessage={titleTooltipMessage}
            />
            {rightButton}
          </div>
        </div>
      )}

      <div className={contentPadding ? 'px-5' : ''}>{children}</div>
    </section>
  );
};

interface UnitTitleProps {
  title?: string;
  titleColor?: string;
  titleTooltipMessage?: string;
}

const UnitTitle = ({ title, titleColor = '#000', titleTooltipMessage }: UnitTitleProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <h3
      className={clsx(
        'flex items-center gap-1.5 text-lg font-bold leading-[2.178rem]',
        titleTooltipMessage && 'cursor-pointer',
      )}
      style={{ color: titleColor }}
      onMouseEnter={titleTooltipMessage ? handleMouseEnter : undefined}
      onMouseLeave={titleTooltipMessage ? handleMouseLeave : undefined}
    >
      {title}
      {titleTooltipMessage && (
        <div className='relative'>
          <IconQuestionCircle height='1.2rem' width='1.2rem' color='black' />
          <Tooltip
            text={<p className='whitespace-pre-wrap'>{titleTooltipMessage}</p>}
            arrowPosition='BOTTOM_CENTER'
            visible={isHovered}
            className='-top-[1.8rem] left-1/2 -translate-x-1/2 -translate-y-full text-xs font-normal text-black'
          />
        </div>
      )}
    </h3>
  );
};
