import { useSuspenseQuery } from '@apollo/client';
import { useTranslations } from 'next-intl';
import { Link } from 'react-transition-progress/next';
import {
  GetPlatformNotificationsDocument,
  PlatformNotificationSortOrder,
} from '@/__generated__/graphql';
import { Unit } from '@/components/layout/unit/Unit';
import { PATHS } from '@/constants/path';
import { dynamicPath } from '@/utils/dynamic-path';
import { formatDateToYMD } from '@/utils/format-date';

export function Notifications() {
  const { data } = useSuspenseQuery(GetPlatformNotificationsDocument, {
    variables: {
      first: 100,
      sortOrder: PlatformNotificationSortOrder.StartPublicAtDesc,
      isImportant: true,
    },
  });
  const t = useTranslations();

  if (!data?.platformNotifications) return null;

  const { edges } = data.platformNotifications;

  // 空配列であればトルツメする
  if (edges.length === 0) return null;

  return (
    <Unit>
      <h1 className='pb-5 text-lg font-bold'>{t('discover_important_notification_title')}</h1>
      <div>
        {edges.map((notice) => {
          return (
            <div
              key={notice.node.id}
              className='-mx-5 border-b-[0.05rem] border-[#D6D6D6] first:border-t-[0.05rem]'
            >
              <Link
                href={dynamicPath(PATHS.NOTIFICATIONS_NEWS_DETAIL, { news_id: notice.node.id })}
                className='flex items-center rounded px-5 py-3'
              >
                <div className='mr-5 w-20 shrink-0 text-sub-12 text-neutral-500'>
                  {formatDateToYMD(notice.node.createdAt)}
                </div>
                <div className='grow text-body-14'>{notice.node.title}</div>
              </Link>
            </div>
          );
        })}
      </div>
    </Unit>
  );
}
