import { useSuspenseQuery } from '@apollo/client';
import { GetTopCarouselsDocument, TopCarouselSortOrder } from '@/__generated__/graphql';
import { Slider } from '@/components/ui/slider/Slider';

export function TopCarousels() {
  const { data } = useSuspenseQuery(GetTopCarouselsDocument, {
    variables: {
      sortOrder: TopCarouselSortOrder.PriorityAsc,
    },
  });

  if (!data || !data.topCarousels) {
    return (
      <div role='status' className='h-56 w-screen animate-pulse bg-gray-300 dark:bg-gray-700' />
    );
  }

  const items = data.topCarousels.map((item) => {
    return { key: item.id, href: item.linkUrl, src: item.image.url, alt: item.imageAlt };
  });

  // 空配列であればトルツメする
  if (items.length === 0) return null;

  return (
    <div className='lg:max-w-md-base'>
      <Slider
        items={items}
        isShownArrows={true}
        isShownPagination={true}
        slidesPerView={1}
        isAutoPlay={false}
      />
    </div>
  );
}
