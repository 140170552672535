import { useSuspenseQuery } from '@apollo/client';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { useEffect, useState, useTransition } from 'react';
import { Link } from 'react-transition-progress/next';
import { GetPickupArtistsDocument } from '@/__generated__/graphql';
import { ErrorRetry } from '@/blocks/shared/errors/error-retry';
import { VirtualWindowGridList } from '@/blocks/shared/virtual-window-grid-list';
import { Unit } from '@/components/layout/unit/Unit';
import { Loading } from '@/components/ui/loading/Loading';
import { DEFAULT_IMAGE_PATH } from '@/constants/image-path';
import { PATHS } from '@/constants/path';
import { useHash } from '@/hooks/use-hash';
import { dynamicPath } from '@/utils/dynamic-path';
import { scrollToSection } from '@/utils/scroll-to-section';

const columnGap = 12;
const rowGap = 16;
const columnCount = 3;
const first = columnCount * 10;

export function PickupArtist() {
  const hash = useHash();

  useEffect(() => {
    if (hash) scrollToSection(hash);
  }, [hash]);

  const [isShowErrorText, setIsShowErrorText] = useState(false);
  const { data, fetchMore } = useSuspenseQuery(GetPickupArtistsDocument, {
    variables: {
      first,
    },
  });
  const { edges, pageInfo } = data.pickupArtists;

  const t = useTranslations();
  const [isPending, startTransition] = useTransition();

  /**
   * 必要に応じて追加のデータをロードする
   */
  const loadMoreRows = async () => {
    if (!pageInfo.hasNextPage) return;
    if (isShowErrorText) return;
    startTransition(() => {
      fetchMore({
        variables: {
          after: pageInfo.endCursor,
        },
      }).catch(() => {
        setIsShowErrorText(true);
      });
    });
  };

  const onClickRetry = async () => {
    await loadMoreRows();
    setIsShowErrorText(false);
  };

  if (edges.length === 0) return null;

  return (
    <Unit id='artists'>
      <h1 className='pb-5 text-lg font-bold'>{t('common_artist')}</h1>

      <VirtualWindowGridList
        columnCount={columnCount}
        columnGap={columnGap}
        rowGap={rowGap}
        overscan={0}
        cacheKey={PATHS.ROOT}
        onEndReached={loadMoreRows}
      >
        {edges.map(({ node }) => {
          const artistSlugForLink = node.artist.slug ?? node.artist.id;
          return (
            <div key={node.artist.id} className='h-[15.4rem]'>
              <Link
                href={dynamicPath(PATHS.ARTISTS, { artist_slug: artistSlugForLink })}
                className='overflow-hidden'
              >
                <div className='mb-2 flex aspect-square overflow-hidden rounded-full'>
                  <Image
                    src={node.artist.iconImage?.url ?? DEFAULT_IMAGE_PATH}
                    alt={node.artist.name}
                    width={200}
                    height={200}
                    className='object-cover'
                  />
                </div>
                <div className='line-clamp-3 break-all text-center text-label-12'>
                  {node.artist.name}
                </div>
              </Link>
            </div>
          );
        })}
      </VirtualWindowGridList>
      {pageInfo.hasNextPage && isPending && !isShowErrorText && (
        <div className='pt-9'>
          <Loading fullscreen={false} />
        </div>
      )}
      {isShowErrorText && <ErrorRetry onClickRetry={onClickRetry} />}
    </Unit>
  );
}
