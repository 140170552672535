import type { IconProps } from './types';

export const IconClose = ({ width, height, color, className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_85_7124)'>
        <g clipPath='url(#clip1_85_7124)'>
          <path
            d='M500 36.4375L463.125 -0.437502L250 212.687L36.875 -0.43752L-1.61186e-06 36.4375L250 286.437L500 36.4375Z'
            fill={color}
          />
        </g>
        <path
          d='M0 463L36.875 499.875L250 286.75L463.125 499.875L500 463L250 213L0 463Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_85_7124'>
          <rect width='500' height='500' fill={color} />
        </clipPath>
        <clipPath id='clip1_85_7124'>
          <rect
            width='286.875'
            height='500'
            fill={color}
            transform='translate(500 -0.4375) rotate(90)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
