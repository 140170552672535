import { useTranslations } from 'next-intl';
import { cn } from '@/utils/cn';

type Props = {
  onClickRetry: () => void;
  textColor?: string;
};

export const ErrorRetry = ({ onClickRetry, textColor = 'black' }: Props) => {
  const t = useTranslations();
  const color = textColor === 'black' ? 'text-black' : `text-${textColor}`;

  return (
    <div className='py-5 text-center'>
      <div>
        <p className='text-body-12 text-kl-red'>{t('common_error_load-more')}</p>
      </div>
      <span
        onClick={onClickRetry}
        className={cn('cursor-pointer', 'underline', color, 'text-link-12')}
      >
        {t('common_retry')}
      </span>
    </div>
  );
};
