import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ja';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * 指定された日付を 'YYYY/M/D' 形式の文字列に変換する
 *
 * @param string - 変換する日付オブジェクト
 * @returns 指定された形式でフォーマットされた日付文字列
 */
export const formatDateToYMD = (date: string) => {
  const localDate = dayjs.utc(date).tz(dayjs.tz.guess());
  return localDate.format('YYYY/M/D');
};

/**
 * 指定された日付を 'YYYY/MM/D/ H:mm' 形式の文字列に変換する
 *
 * @param string - SO 8601形式の日時文字列
 * @returns 指定された形式でフォーマットされた日付文字列
 */
export const formatDateToYMDHM = (date: string) => {
  const localDate = dayjs.utc(date).tz(dayjs.tz.guess());
  return localDate.format('YYYY/M/D H:mm');
};

/**
 * 指定された日付を 'YYYY/M/D (ddd)' 形式の文字列に変換する
 *
 * @param date - 変換する日付文字列
 * @param isJa - 日本語でフォーマットするかどうか
 * @returns 指定された形式でフォーマットされた日付文字列
 */
export const formatDateWithLocalizedWeekday = (date: string, isJa: boolean) => {
  if (isJa) {
    dayjs.locale('ja');
  }
  const localDate = dayjs.utc(date).tz(dayjs.tz.guess());
  return localDate.format('YYYY/M/D (ddd)');
};

/**
 * 指定された日時文字列をローカルタイムに変換し、'H:mm'形式でフォーマットする関数。
 * @param date - 変換する日時文字列。ISO 8601形式のUTC日時またはローカルタイム形式の時間文字列。
 * @returns フォーマットされたローカルタイムの時間文字列 ('H:mm'形式)。
 */
export const formatTimeToLocalHM = (date: string) => {
  let localDate: dayjs.Dayjs;

  // ISO 8601形式のUTC日時文字列の場合
  if (date.includes('T')) {
    localDate = dayjs.utc(date).tz(dayjs.tz.guess());
  } else {
    // ローカルタイム形式の時間文字列の場合
    const today = dayjs().format('YYYY-MM-DD');
    localDate = dayjs(`${today}T${date}`).tz(dayjs.tz.guess());
  }

  return localDate.format('H:mm');
};

/**
 * 指定された日付を 'MM/D H:mm' 形式の文字列に変換する
 *
 * @param date - ISO 8601形式の日時文字列
 * @returns 指定された形式でフォーマットされた日付文字列
 */
export const formatDateToMDHM = (date: string) => {
  const localDate = dayjs.utc(date).tz(dayjs.tz.guess());
  return localDate.format('M/D H:mm');
};
