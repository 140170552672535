import { useState, useEffect } from 'react';

/**
 * URLハッシュの変更を監視し、現在のハッシュをstateとして返します
 * コンポーネントがマウントされたときとハッシュが変更されたときに動作します
 */
export const useHash = (): string => {
  const [hash, setHash] = useState('');

  useEffect(() => {
    const onHashChange = () => {
      setHash(window.location.hash);
    };

    setHash(window.location.hash);

    window.addEventListener('hashchange', onHashChange);
    return () => window.removeEventListener('hashchange', onHashChange);
  }, []);

  return hash;
};
