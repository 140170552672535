'use client';

import type { ReactNode } from 'react';
import { Button } from '@/components/ui/button/Button';
import type { IconComponentType } from '@/components/ui/button/types';
import { IconArrowLeft } from '@/components/ui/icons/IconArrowLeft';
import { IconLogo } from '@/components/ui/icons/IconLogo';
import { cn } from '@/utils/cn';

type Props = {
  secondary?: boolean;
  logo?: boolean;
  RightIconComponent?: IconComponentType;
  title?: string;
  desktopHeaderHidden?: boolean;
  information?: ReactNode;
  backButton?: boolean;
  backAction?: () => void;
  rightIconOnClick?: () => void;
  className?: string;
};

export const Header = ({
  secondary = false,
  logo = false,
  RightIconComponent,
  title,
  information,
  desktopHeaderHidden = false,
  backButton = false,
  backAction,
  rightIconOnClick,
  className,
}: Props) => {
  const handleBackClick = () => {
    if (backAction) {
      backAction();
    } else {
      history.back();
    }
  };

  const bgColor = secondary ? 'bg-kl-black' : 'bg-kl-dark';

  return (
    <header
      className={cn(
        'sticky top-0 z-10 h-header w-full px-5 text-white',
        desktopHeaderHidden && 'lg:hidden',
        bgColor,
        className,
      )}
    >
      <div className='flex h-full items-center'>
        <div className='-m-4 flex shrink-0 cursor-pointer p-4' onClick={handleBackClick}>
          {backButton && (
            <Button
              variant='iconOnly'
              IconComponent={IconArrowLeft}
              color='white'
              className='size-[1.4rem]'
            />
          )}
        </div>
        {logo ? (
          <IconLogo width={105} height={20} color='white' className='mx-auto' />
        ) : information ? (
          <div className='pointer-events-none flex-1 break-all text-center'>
            <div className='line-clamp-2 text-head-16'>{title}</div>
            <div className='line-clamp-1 text-sub-12 text-[#D0D0D0]'>{information}</div>
          </div>
        ) : (
          <div className='pointer-events-none flex-1 text-center text-head-16'>{title}</div>
        )}
        <div className='-m-4 flex shrink-0 cursor-pointer p-4' onClick={rightIconOnClick}>
          {RightIconComponent && (
            <Button
              variant='iconOnly'
              IconComponent={RightIconComponent}
              color='white'
              className='size-[1.4rem]'
            />
          )}
        </div>
      </div>
    </header>
  );
};
