'use client';
import clsx from 'clsx';
import React from 'react';

interface Props {
  children: React.ReactNode;
  bgColor?: string;
  paddingBottom?: boolean;
  className?: string;
}

export const PageWrapper = ({
  bgColor = 'transparent',
  children,
  paddingBottom = true,
  className,
}: Props) => {
  return (
    <div
      // TODO: Footerもstickyに改修したのち、Gridレイアウトでmainの領域を計算するようにする
      className={clsx(
        'min-h-dvh',
        'relative',
        paddingBottom && 'pb-[var(--bottom-nav-height)] lg:pb-0',
        className,
      )}
      style={{ backgroundColor: bgColor }}
    >
      {children}
    </div>
  );
};
