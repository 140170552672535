'use client';

import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Notifications } from '@/blocks/home/notifications';
import { PickupArtist } from '@/blocks/home/pickup-artist';
import { PickupEvents } from '@/blocks/home/pickup-events';
import { TopCarousels } from '@/blocks/home/top-carousels';
import { ErrorUnit } from '@/blocks/shared/errors/error-unit';
import { Header } from '@/components/layout/header';
import { PageWrapper } from '@/components/layout/page/PageWrapper';
import { UnitContainer } from '@/components/layout/unit/UnitContainer';
import { Loading } from '@/components/ui/loading/Loading';

export default function Page() {
  return (
    <PageWrapper className='isolate lg:pt-0'>
      <Suspense fallback={<Loading />}>
        <div className='sticky top-0 z-20'>
          <Header logo={true} desktopHeaderHidden={true} className='relative' />
        </div>
        <div className='w-full md:w-md-base lg:top-0'>
          <TopCarousels />
        </div>
        <main>
          <UnitContainer className='z-10'>
            <ErrorBoundary fallback={<ErrorUnit />}>
              <div className='grid gap-0.5x'>
                <Notifications />
                <PickupEvents />
                <PickupArtist />
              </div>
            </ErrorBoundary>
          </UnitContainer>
        </main>
      </Suspense>
    </PageWrapper>
  );
}
